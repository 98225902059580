import axios from 'axios';

// REQUESTS
export const getCurrentUser = () => axios.get('/auth/api/users/security/auth');

export const login = (
  username, password,
) => axios.post('/authentication/api/open/authenticate', {
  login: username.toLowerCase(),
  password,
});

export const changePassword = (
  username, currentPassword, newPassword,
  // toLowerCase: Fix in order to prevent error: https://creativedata.atlassian.net/browse/PRODUCT-2497
) => axios.put('/authentication/api/open/password/change', {
  login: username.toLowerCase(),
  oldPassword: currentPassword,
  newPassword,
});

export const getJobs = () => axios.get('/profiles/api/jobs/all');

export const getProfile = (username) => axios.get(`/profiles/api/${username.toLowerCase()}`);

export const editProfile = (user) => axios.put(
  `/profiles/api/${user.login.toLowerCase()}`,
  { ...user, login: user?.login?.toLowerCase() },
);

export const forgotPassword = (username) => axios
  // toLowerCase: Fix in order to prevent error: https://creativedata.atlassian.net/browse/PRODUCT-2497
  .put('/authentication/api/open/password/reset/trigger', { login: username.toLowerCase() });

export const resetPassword = (password, token) => axios
  .put('/authentication/api/open/password/reset', {
    password,
    token,
  });
